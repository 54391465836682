import { render, staticRenderFns } from "./csConfigAddEdit.vue?vue&type=template&id=5aff0b5c&scoped=true&"
import script from "./csConfigAddEdit.vue?vue&type=script&lang=js&"
export * from "./csConfigAddEdit.vue?vue&type=script&lang=js&"
import style0 from "./csConfigAddEdit.vue?vue&type=style&index=0&id=5aff0b5c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aff0b5c",
  null
  
)

export default component.exports