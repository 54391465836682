<template>
  <div class="main">
    <el-form :label-position="'left'" label-width="80px" style="margin: 20px; width: 60%; min-width: 600px">
      <el-form-item label="id">
        {{ csConfig != null ? csConfig.id : "" }}
      </el-form-item>
      <el-form-item label="标题">
        <el-input v-model="csConfig.title"></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="csConfig.remark"></el-input>
      </el-form-item>
    </el-form>
    <div class="rule-list">
      <div class="rule-item">
        <div class="btn"></div>
        <div class="employee">负责人</div>
        <div class="qrcode">二维码</div>
        <div class="time">开始时间</div>
        <div class="time">结束时间</div>
      </div>

      <div class="rule-item" v-for="(item, index) in csConfig.rule.ruleList" :key="'ruleList' + index">
        <div class="btn">
          <el-button size="mini" round @click="onAdd(index)">新增</el-button
          ><el-button size="mini" round @click="onDelete(index)">删除</el-button
          ><el-button size="mini" round @click="onUp(index)">上移</el-button
          ><el-button size="mini" round @click="onDown(index)">下移</el-button>
        </div>
        <div class="employee">
          <el-input v-model="item.id" v-show="false"></el-input>
          <el-input v-model="item.employee"></el-input>
        </div>
        <div class="qrcode">
          <el-input type="textarea" v-model="item.url" :rows="5"></el-input>
        </div>
        <div class="time">
          <el-select v-model="item.startDayOfWeek" placeholder="周几">
            <el-option v-for="item in dayOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <el-time-select
            v-model="item.startTime"
            :picker-options="{
              start: '00:00',
              step: '01:00',
              end: '24:00',
            }"
            placeholder="选择时间"
          >
          </el-time-select>
        </div>
        <div class="time">
          <el-select v-model="item.endDayOfWeek" placeholder="周几">
            <el-option v-for="item in dayOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <el-time-select
            v-model="item.endTime"
            :picker-options="{
              start: '00:00',
              step: '01:00',
              end: '24:00',
            }"
            placeholder="选择时间"
          >
          </el-time-select>
        </div>
      </div>
    </div>
    <div class="rule-list">
      <div style="text-align:left">托底的规则</div>
      <div class="rule-item">
        <div class="btn"></div>
        <div class="employee">
          <el-input v-model="csConfig.rule.elseRule.id" v-show="false"></el-input>
          <el-input v-model="csConfig.rule.elseRule.employee"></el-input>
        </div>
        <div class="qrcode">
          <el-input type="textarea" v-model="csConfig.rule.elseRule.url" :rows="5"></el-input>
        </div>
        <div class="time">
          <el-select v-model="csConfig.rule.elseRule.startDayOfWeek" placeholder="周几">
            <el-option v-for="item in dayOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <el-time-select
            v-model="csConfig.rule.elseRule.startTime"
            :picker-options="{
              start: '00:00',
              step: '01:00',
              end: '24:00',
            }"
            placeholder="选择时间"
          >
          </el-time-select>
        </div>
        <div class="time">
          <el-select v-model="csConfig.rule.elseRule.endDayOfWeek" placeholder="周几">
            <el-option v-for="item in dayOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <el-time-select
            v-model="csConfig.rule.elseRule.endTime"
            :picker-options="{
              start: '00:00',
              step: '01:00',
              end: '24:00',
            }"
            placeholder="选择时间"
          >
          </el-time-select>
        </div>
      </div>
    </div>
    <div class="tool">
      <el-button type="primary" @click="onSave">保存</el-button>
    </div>
  </div>
</template>

<script>
import msgLogger from "@/util/msgLogger";
import { csConfigApi } from "@/api/csConfigApi";
import dayjs from "dayjs";
import { nanoid } from "nanoid";

export default {
  components: {},
  data() {
    return {
      //doNotDisplay: false,
      // 获取星期几，8表示星期日，2表示星期一，7是星期六，以此类推
      dayOption: [
        {
          value: 2,
          label: "星期一",
        },
        {
          value: 3,
          label: "星期二",
        },
        {
          value: 4,
          label: "星期三",
        },
        {
          value: 5,
          label: "星期四",
        },
        {
          value: 6,
          label: "星期五",
        },
        {
          value: 7,
          label: "星期六",
        },
        {
          value: 8,
          label: "星期日",
        },
      ],
      csConfig: {
        id: "0",
        title: "",
        remark: "",
        rule: {
          ruleList: [
            {
              id: nanoid(8),
              employee: "",
              url: "",
              startDayOfWeek: "2",
              endDayOfWeek: "8",
              startTime: "00:00",
              endTime: "24:00",
            },
          ],
          elseRule: {
            id: nanoid(8),
            employee: "",
            url: "",
            startDayOfWeek: "2",
            endDayOfWeek: "8",
            startTime: "00:00",
            endTime: "24:00",
          },
        },
      },
    };
  },
  methods: {
    updateUserAttach(retList) {
      console.log("update user attach", retList);
    },
    initData(id) {
      console.log("id", id);
      csConfigApi.getById(id).then((ret) => {
        let { code, data } = ret;
        if (code == 0 && data) {
          console.log("#1", ret);
          this.csConfig = data;
        }
      });
    },
    onAdd(index) {
      let obj = {
        id: nanoid(8),
        employee: "",
        url: "",
        startDayOfWeek: "2",
        endDayOfWeek: "8",
        startTime: "00:00",
        endTime: "24:00",
      };
      this.csConfig.rule.ruleList.splice(index + 1, 0, obj);
    },
    onDelete(index) {
      if (this.csConfig.rule.ruleList.length === 1) {
        msgLogger.warn("不能再删了");
        return;
      }
      this.csConfig.rule.ruleList.splice(index, 1);
    },
    onUp(index) {
      if (index == 0) {
        msgLogger.warn("无法再上移");
        return;
      }
      /**
			 * rray.splice(index,howmany,item1,.....,itemX)
参数 Values
参数	描述
index	必需。规定从何处添加/删除元素。
该参数是开始插入和（或）删除的数组元素的下标，必须是数字。
howmany	可选。规定应该删除多少元素。必须是数字，但可以是 "0"。
如果未规定此参数，则删除从 index 开始到原数组结尾的所有元素。
item1, ..., itemX	可选。要添加到数组的新元素
			 */
      let temp = this.csConfig.rule.ruleList.splice(index - 1, 1);
      this.csConfig.rule.ruleList.splice(index, 0, temp[0]);
    },
    onDown(index) {
      if (index == this.csConfig.rule.ruleList.length - 1) {
        msgLogger.warn("无法再下移");
        return;
      }
      let temp = this.csConfig.rule.ruleList.splice(index, 1);
      this.csConfig.rule.ruleList.splice(index + 1, 0, temp[0]);
    },
    onSave() {
      csConfigApi.addCsConfig(this.csConfig).then((ret) => {
        let { code, data } = ret;
        if (code == 0 && data) {
          console.log("#2", data);
          this.csConfig = data;
          msgLogger.done("保存成功");
        }
      });
    },
  },
  mounted() {
    if (this.$route && this.$route.query && this.$route.query.id) {
      const id = this.$route.query.id;
      this.initData(id);
    }
  },
  filters: {
    timeStr: function(value) {
      let day = dayjs(Number(value));
      return day.format("YYYY-MM-DD hh:mm");
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  display: flex;
  flex-direction: column;
  .rule-list {
    display: flex;
    flex-direction: column;
    .rule-item {
      display: flex;
      flex-direction: row;
      margin: 10px 0px;
      .btn {
        width: 300px;
        margin: 0px 10px;
      }
      .employee {
        width: 200px;
        margin: 0px 10px;
      }
      .qrcode {
        width: 300px;
        margin: 0px 10px;
      }
      .time {
        margin: 0px 10px;
        width: 250px;
        display: flex;
        flex-direction: row;
      }
    }
  }
}
</style>
